<template>
    <div>
      <Header v-if="$route.meta.header"/>
      <router-view/>
    </div>
</template>

<script>

import Header from "@/components/layout/Header";
export default {
  name: 'App',
  components: {Header},
  data: () => ({
    //
  }),
  mounted() {
    this.$store.dispatch('set_date')
  }
};
</script>
