import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    login: false,
    token: null,
    date: '',
  },
  mutations: {
    setLogin (state, payload) {
      state.login = payload;
    },
    setToken (state, payload) {
      state.token = payload;
    },
  },
  getters: {
    getLogin( state ) {
      return state.login;
    },
    getDate( state ) {
      return state.date;
    }
  },
  actions: {
    check_token( {commit} ) {
      let token = localStorage.getItem('token');
      if( !token ) {
        return false
      }
      commit('setToken', token)
      commit('setLogin', true)
    },

    set_date( {state} ) {
      let months = ["يناير","فبراير","مارس","ابريل","مايو","يونيو","يوليو","اغسطس","سبتمبر","أكتوبر","نوفمبر","ديسمبر"];
      let time = new Date();
      let month = months[time.getMonth()];
      let day = time.getDate();
      let year = time.getFullYear();
      state.date = `${day} ${month} ${year}`;
    }
  },
  modules: {
  }
})
