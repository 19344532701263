import Vue from 'vue'
import VueRouter from 'vue-router'
import Vuex from "@/store";
Vue.use(VueRouter)

const AdminGuard = (before, after, next) => {
  Vuex.dispatch('check_token').then(() => {
    if(Vuex.state.token !== null) {
      next()
    } else {
      next('/')
    }
  })
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => (import('@/views/Home.vue')),
    meta:{ header: true}
  },
  {
    path: '/page/login',
    name: 'Login',
    component: () => (import('@/views/Auth/Login.vue'))
  },
  {
    path: '/well-done',
    name: 'Done',
    component: () => (import('@/views/WellDone.vue'))
  },
  {
    path: '/:name',
    name: 'CreatedPage',
    component: () => (import('@/views/CreatedPage.vue')),
    meta:{ header: true}
  },
  {
    path: '/page/admin',
    name: 'Admin',
    component: () => (import('@/views/Admin/index.vue')),
    children: [
      {
        path: '/',
        name: 'Users',
        component: () => (import('@/views/Admin/Users.vue'))
      },
      {
        path: 'create-comment',
        name: 'CreateComments',
        component: () => (import('@/views/Admin/createComments.vue'))
      },
      {
        path: 'create-pages',
        name: 'CreatePages',
        component: () => (import('@/views/Admin/CreatePages.vue'))
      },
      {
        path: 'pages-list',
        name: 'PagesList',
        component: () => (import('@/views/Admin/PagesList.vue'))
      },
      {
        path: 'change-password',
        name: 'ChangePassword',
        component: () => (import('@/views/Admin/ChangePassword.vue'))
      }
    ],
    beforeEnter: AdminGuard,
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
