<template>
  <header>
    <!-- top -->
    <div class="top_head d-none">
      <div class="my_container">
        <div class="date">
          <v-row>
            <v-col cols="9"></v-col>
            <v-col cols="3">
              <div class="wrapper_date">
                <div>
                  <p class="f_bold"> {{ get_date }}</p>
                </div>
                <div>
                  <p class="f_bold">ألارشيف</p>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>


    <!-- links -->
    <div class="the_links">
      <div class="my_container">
        <p class="head">
          <span class="">
              <v-app-bar-nav-icon></v-app-bar-nav-icon>
          </span>
          <span class="f-30 gold"> الوطن الان -</span>
          <span class="f-30 red">اخر الاخبار و الاحداث</span>
        </p>

        <nav>
            <ul>
              <li class="f_bold" v-for="(item,index) in list" :key="index">{{ item }}</li>
            </ul>
        </nav>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  data:() => ({
    date: '',
    list: [
        "الرئيسية",
      "محليات",
      "السياسة",
      "اقتصاد",
      "كتاب ومقالات",
      "ثقافة",
      "رياضة",
      "أحوال الناس",
      " اماراتي ",
      "صوت المواطن",
      "الملاحق",
      "صوت المواطن",
    ],
  }),
  computed: {
    get_date() {
       return this.$store.getters.getDate
    }
  },
  mounted() {
  }
}
</script>